import ProIndex from '../components/pros/ProIndex.vue'
import APTherapistAvailability from '../components/public/APTherapistAvailability'
import About from '../components/About.vue'
import AskForResetPassword from '../components/AskForResetPassword.vue'
import ChangePassword from '../components/ChangePassword.vue'
import Animals from '../components/pros/animals/Animals.vue'
import APAnimal from '../components/pros/animals/APAnimal.vue'
import EstimationsList from '../components/pros/EstimationsList.vue'
import Estimations from '../components/pros/Estimations.vue'
import Billing from '../components/pros/Billing.vue'
import BillingList from '../components/pros/BillingList.vue'
import Cgu from '../components/CGU.vue'
import Clubs from '../components/pros/clubs/Clubs.vue'
import Club from '../components/pros/clubs/Club.vue'
import ClubAmountDueDetails from '../components/pros/clubs/ClubAmountDueDetails.vue'
import DemoThanks from '../components/pros/DemoThanks.vue'
import Patients from '../components/pros/patients/Patients.vue'
import Patient from '../components/pros/patients/Patient.vue'
import PatientForm from '../components/pros/patients/PatientForm'
import Prices from '../components/pros/Prices.vue'
import Sessions from '../components/pros/sessions/Sessions.vue'
import Session from '../components/pros/sessions/Session.vue'
import Signup from '../components/Signup.vue'
import MainSettings from '../components/pros/settings/MainSettings'
import User from '../components/pros/User.vue'
import Administration from '../components/pros/Administration.vue'
import APTeam from '../components/pros/APTeam.vue'
import APPersonalization from '../components/pros/settings/APPersonalization.vue'
import APVacations from '../components/pros/settings/APVacations.vue'
import APSessionsTypes from '../components/pros/APSessionsTypes.vue'
import PageNotFound from '../components/PageNotFound.vue'
import PaymentNeeded from '../components/pros/PaymentNeeded.vue'
import FinancialForecast from '../components/pros/finance/FinancialForecast.vue'
import APTherapists from '../components/public/APTherapists.vue'
import PublicIndex from '../components/public/PublicIndex'
import PublicGreetings from '../components/public/PublicGreetings'
import PublicSession from '../components/public/PublicSession'
import PublicSessionWaitingValidation from '../components/public/PublicSessionWaitingValidation'
import PatientHomepage from '../components/patients/PatientHomepage'
import PatientMain from '../components/patients/PatientMain'
import MainPatientSettings from '../components/patients/settings/MainPatientSettings'
import PatientAccount from '../components/patients/settings/PatientAccount'

import * as VueRouter from 'vue-router';

const router = VueRouter.createRouter({
    history: VueRouter.createWebHistory(),
    routes: [
        {
            path: '/',
            name: 'PublicIndex',
            component: PublicIndex,
            children: [
                {
                    path: '',
                    name: 'PublicGreetings',
                    component: PublicGreetings
                },
                {
                    path: '/public/about',
                    name: 'PublicAbout',
                    component: About
                },
                {
                    path: '/public/cgu',
                    name: 'PublicCgu',
                    component: Cgu
                },
                {
                    path: '/therapists',
                    name: 'APTherapists',
                    component: APTherapists,
                    props: { name : ''}
                },
                {
                    path: '/therapists/:id',
                    name: 'APTherapistAvailability',
                    component: APTherapistAvailability
                },
                {
                    path: '/public/session',
                    name: 'PublicSession',
                    component: PublicSession,
                    props: true
                },
                {
                    path: '/public/validation/waiting/session',
                    name: 'PublicSessionWaitingValidation',
                    component: PublicSessionWaitingValidation
                }         
            ]
        },
        {
            path: '/patients/user',
            name: 'MainPatientSettings',
            component: MainPatientSettings,
            children: [
                {
                    path:'',
                    name: PatientAccount,
                    component: PatientAccount
                }
            ]
        },
        {
            path: '/patient',
            name: 'PatientHomepage',
            component: PatientHomepage,
            children: [
                {
                    path: '',
                    name: 'PatientMain',
                    component: PatientMain
                }
            ]
        },
        {
            path: '/pros/user',
            name: 'MainSettings',
            component: MainSettings,
            children: [
                {
                    path:'',
                    name: User,
                    component: User
                },
                {
                    path:'/pros/personalization',
                    name: APPersonalization,
                    component: APPersonalization
                },
                {
                    path:'/pros/vacations',
                    name: APVacations,
                    component: APVacations
                }
            ]
        },
        {
            path: '/pros',
            name: 'ProIndex',
            component: ProIndex, 
            children: [
                {
                    path: '/pros/about',
                    name: 'About',
                    component: About
                },
                {
                    path: '/pros/cgu',
                    name: 'Cgu',
                    component: Cgu
                },
                {
                    path: '/pros/payment-blocked',
                    name: 'PaymentNeeded',
                    component: PaymentNeeded
                },
                {
                    path: '/pros/prices',
                    name: 'Prices',
                    component: Prices
                },
                {
                    path: '/pros/sign-up',
                    name: 'Signup',
                    component: Signup
                },
                {
                    path: '/pros/session',
                    name: 'CreateSession',
                    component: Session
                },
                {
                    path: '/pros/session/:id',
                    name: 'EditSession',
                    component: Session
                },
                {
                    path: '/pros/sessions',
                    name: 'Sessions',
                    component: Sessions
                },
                {
                    path: '/pros/patient',
                    name: 'CreatePatient',
                    component: PatientForm
                },
                {
                    path: '/pros/patient/:id',
                    name: 'EditPatient',
                    component: PatientForm
                },
                {
                    path: '/pros/patients',
                    name: 'Patients',
                    component: Patients
                },
                {
                    path: '/pros/patients/:id/details',
                    name: 'PatientWithId',
                    component: Patient
                },
                {
                    path: '/pros/animals',
                    name: 'Animals',
                    component: Animals
                },
                {
                    path: '/pros/animal',
                    name: 'CreateAnimal',
                    component: APAnimal
                },
                {
                    path: '/pros/animal/:id',
                    name: 'EditAnimal',
                    component: APAnimal
                },
                {
                    path: '/pros/estimations',
                    name: 'Estimations',
                    component: Estimations
                },
                {
                    path: '/pros/estimations/details',
                    name: 'EstimationList',
                    component: EstimationsList
                },
                {
                    path: '/pros/billings',
                    name: 'Billing',
                    component: Billing,
                },
                {
                    path: '/pros/billings/details',
                    name: 'BillingList',
                    component: BillingList
                },
                {
                    path: '/pros/clubs',
                    name: 'Clubs',
                    component: Clubs
                },
                {
                    path: '/pros/clubs/:id',
                    name: 'ClubWithId',
                    component: Club
                },
                {
                    path: '/pros/clubs/amount-details',
                    name: 'ClubAmountDueDetails',
                    component: ClubAmountDueDetails
                },
                {
                    path: '/pros/administration',
                    name: 'Administration',
                    component: Administration
                },
                {
                    path: '/pros/demoThanks',
                    name: 'DemoThanks',
                    component: DemoThanks
                },
                {
                    path: '/pros/team',
                    name: 'APTeam',
                    component: APTeam
                },
                {
                    path: '/pros/sessions-types',
                    name: 'APSessionsTypes',
                    component: APSessionsTypes
                },
                {
                    path: '/pros/finance/forecast',
                    name: 'FinancialForecast',
                    component: FinancialForecast
                }
            ]
        },
        
        {
            path: '/reset-password',
            name: 'AskForResetPassword',
            component: AskForResetPassword
        },
        {
            path: '/change-password',
            name: 'ChangePassword',
            component: ChangePassword
        },
        
        { path: '/:pathMatch(.*)*', component: PageNotFound }
      ]
});

export default router;