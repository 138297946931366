import axios from 'axios';
import authHeaders from '../../../services/auth-header';
import { ROLES } from '../../../services/roles';

const USER_URL = process.env.VUE_APP_BASE_AP_URL+`/user`;

export async function getTherapists() {
	return axios.get(`${USER_URL}/team?role=${ROLES.THERAPIST.value}`, {headers : { 'Authorization':authHeaders()}})
	.then(response => {
		if (response.data) {
			return Promise.resolve(response.data);
		}
	})
	.catch(function (error) {
		return Promise.reject(error);
	});
}